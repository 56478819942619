<template>
  <section>
    <BaseProductPage
      :cols="cols"
      :formField="formField"
      :resetUrl="url"
      :isSearch="isSearch"
      :referSizeSpanCol="['参考尺码', '尺码']"
      v-p="['externaladmin:product:maintain:knifeMange']"
    >
      <template #tips>
        <el-alert title="上架的尺码无法修改 psd 文件" type="warning" show-icon> </el-alert>
      </template>
      <template>
        <el-table-column fixed="right" align="center" label="操作" width="140px">
          <template slot-scope="{ row }">
            <PsdBtn
              v-p="['externaladmin:product:maintain:knifeMange:editPsd']"
              :data="row"
              v-if="row.$sizeList && row.$sizeList.length"
            />
          </template>
        </el-table-column>
      </template>
    </BaseProductPage>
  </section>
</template>

<script>
import BaseProductPage from '../../baseProductPage'
import PsdBtn from './module/psdBtn'

import { knifeMangeField as formField } from '../../field'
import { knifeMangeCols as cols } from '../../cols'
import { mapGetters } from 'vuex'
export default {
  name: 'knifeMange',
  components: {
    BaseProductPage,
    PsdBtn
  },
  data() {
    return {
      isSearch: this.checkPermission(['externaladmin:product:maintain:knifeMange:search']),
      url: '/externaladmin/prototypeService/productPrototype/list'
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    cols({ isMain }) {
      if (!isMain) {
        return cols.filter((item) => item.value !== 'manager')
      }
      return cols
    },
    formField({ isMain }) {
      if (!isMain) {
        return formField.filter((item) => item.value !== 'manager')
      }
      return formField
    }
  }
}
</script>

<style lang="scss" scoped>
.el-alert {
  margin-top: -20px;
  margin-bottom: 20px;
}
</style>
